import request from '@/utils/request';

const BASEURL = process.env.VUE_APP_GOOGLE_BASEURL

const POST = (url, data) => request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    transformRequest: [function (data) {
        return JSON.stringify(data)
    }]
})

const GET = (url, params) => request({
    url,
    baseURL: BASEURL,
    method: 'get',
    params
})

/**
 * @description: 查询需要导入到凭证管理的所有凭证
 * @param {*} params
 * @return {Array} res.data
 */
export const remoteQuery = params => GET('/google/credential/remoteQuery', params);

/**
 * @description: 将某个凭证绑定给当前用户
 * @param {*} data
 * @param {string} data.credentialIds 凭证id，多个用英文逗号隔开
 * @return {*}
 */
export const bindGimpUser = data => GET('/google/credential/bindGimpUser', data);

/**
 * @description: 查询所有凭证
 * @param {*} data
 * @return {*}
 */
export const findAll = data => POST('/google/credential/findAll', data);

/**
 * @description: 更新凭证
 * @param {*} data
 * @return {*}
 */
export const updateCredential = data => POST('/google/credential/update', data)

/**
 * @description: 拉取凭证下的广告账户结构
 * @param {*} data
 * @return {*}
 */
export const pullMcc = data => GET(`/google/credential/pullMcc`, data)
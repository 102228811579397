<template>
  <el-container class="auth-wrap bg-theme">
    <div class="content bg-f w-100">
      <div class="cus-header dp-flex justify-btw align-item-c title-color">
        <span>凭证管理</span>
        <el-button type="primary" plain size="small" @click="handleSyncVoucher">
          同步凭证
        </el-button>
      </div>
      <el-table :data="tableData" :height="tableHeight" style="width: 100%">
        <el-table-column label="账户信息" width="370">
          <template slot-scope="scope">
            <div
              style="cursor: pointer"
              class="dp-flex align-item-c"
              @click="changeCurrentNum(scope.row.id)"
            >
              <div>
                <!--  v-if="scope.row.flag != 's'" -->
                <img
                  class="mr-10"
                  :src="scope.row.thirdUser.thirdUserAvatarUrl"
                  style="width: 32px; height: 32px; border-radius: 50%"
                />
              </div>
              <div style="line-height: initial">
                <p>
                  <img
                    src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
                    style="width: 12px; height: 12px; border-radius: 50%"
                  />
                  {{
                    scope.row.displayName
                      ? scope.row.displayName
                      : scope.row.thirdUser.thirdUserName
                  }}
                  <span class="small-gray"
                    >({{ scope.row.thirdUser.thirdUserEmail }})</span
                  >
                </p>
                <p class="small-gray">
                  ID：{{ scope.row.thirdUser.thirdUserId }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="最后授权时间" prop="update_time">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div class="dp-flex align-item-c">
              <span
                :class="[
                  'small-circle',
                  {
                    'bg-success': scope.row.status == 'VALID',
                    'bg-danger': scope.row.status == 'INVALID',
                    'bg-info': scope.row.status == 'EXPIRED',
                  },
                ]"
              ></span>
              <span style="margin-left: 8px">{{
                scope.row.status == "VALID"
                  ? "有效"
                  : scope.row.status == "INVALID"
                  ? "无效"
                  : "过期"
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown
              size="small"
              placement="bottom"
              @command="(command) => handleCommand(command, { row: scope.row })"
            >
              <el-button type="text">
                操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in authOperates"
                  :key="index"
                  :command="item.id"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <sync-voucher
      :show.sync="showSyncVoucher"
      :list="remoteVouchers"
      @onsync="submitSyncVoucher"
    ></sync-voucher>
    <el-dialog title="修改状态" :visible.sync="showChangeStatus" width="400px">
      <el-form>
        <el-form-item label="选择状态">
          <el-radio-group v-model="voucherStatus">
            <el-radio label="VALID">有效</el-radio>
            <el-radio label="INVALID">无效</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showChangeStatus = false">取 消</el-button>
        <el-button type="primary" @click="handleChangeStatus">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import syncVoucher from "./components/syncVoucher.vue";
import { tableHeight } from "@/utils/utils.js";
import {
  remoteQuery,
  bindGimpUser,
  findAll,
  updateCredential,
  pullMcc,
} from "@/api/google/cashVoucher";
export default {
  components: {
    syncVoucher,
  },
  data() {
    return {
      // 设置高度需要的数据
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".auth-wrap", cssProperty: "paddingTop" },
        { selector: ".auth-wrap", cssProperty: "paddingBottom" },
        { selector: ".cus-header", cssProperty: "height" },
        { selector: ".cus-header", cssProperty: "marginBottom" },
        { selector: ".pagination", cssProperty: "height" },
      ],
      tableData: [],
      tableHeight: 200,
      authOperates: [
        { id: 3, name: "修改状态", disabled: false },
        { id: 4, name: "重命名", disabled: false },
        { id: 6, name: "绑定优化师", disabled: false },
        { id: 7, name: "同步账户", disabled: false },
        { id: 8, name: "解除绑定", disabled: false },
      ],
      showSyncVoucher: false,
      remoteVouchers: [],
      enumStatus: ["VALID", "INVALID"],
      showChangeStatus: false,
      voucherStatus: "",
      changeStatusId: "",
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  mounted() {
    this.getUserList();
    this.getTableHeight()
  },
  methods: {
    // 同步凭证
    handleSyncVoucher() {
      this.$showLoading();
      remoteQuery().then((res) => {
        this.remoteVouchers = res.data;
        this.showSyncVoucher = true;
        this.$hideLoading();
      });
    },
    submitSyncVoucher(list) {
      // todo 调同步接口
      const data = {
        credentialIds: list.join(","),
      };
      bindGimpUser(data)
        .then((res) => {
          console.log(res);
          this.$message.success("同步完成");
          this.showSyncVoucher = false;
          this.getUserList();
        })
        .catch((err) => {
          this.$message.error(err.comment);
        });
    },
    // 4. 重命名
    rename(credentialId, name) {
      this.$showLoading();
      updateCredential({ credentialId, displayName: name })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success(`账户重命名成功！`);
            this.getUserList();
          }
        })
        .catch((err) => {
          this.$message.error(err.comment);
          this.$hideLoading();
        });
    },
    // 6. 绑定优化师
    bindUid(credentialId, bindUid) {
      this.$showLoading();
      updateCredential({ credentialId, bindUid })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "绑定成功",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "warning",
              message: err.comment,
            });
          }
        })
        .catch((err) => {
          this.$message.error(err.comment);
          this.$hideLoading();
        });
    },
    // 3. 修改状态
    handleChangeStatus() {
      this.$showLoading();
      const { changeStatusId, voucherStatus } = this;
      updateCredential({ credentialId: changeStatusId, status: voucherStatus })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.changeStatusId = "";
            this.voucherStatus = "";
            this.showChangeStatus = false;
            this.getUserList();
          } else {
            this.$message({
              type: "warning",
              message: err.comment,
            });
          }
        })
        .catch((err) => {
          this.$message.error(err.comment);
          this.$hideLoading();
        });
    },
    // 7. 同步账户
    pullMcc(googleCredentialId) {
      this.$showLoading();
      pullMcc({ googleCredentialId })
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success("同步任务开始执行...");
          } else {
            this.$message.error(res.comment);
          }
        })
        .catch((err) => {
          this.$message.error(err.comment);
          this.$showLoading();
        });
    },
    unbind(credentialId) {
      this.$confirm("确定要解除绑定当前凭证?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateCredential({
            credentialId,
            unbind: true,
          })
            .then((res) => {
              this.$message.success("解绑成功");
              this.getUserList();
            })
            .catch((err) => {
              this.$message.err(err.comment);
            });
        })
        .catch(() => {});
    },
    handleCommand(command, { row }) {
      switch (command) {
        // 设为默认
        case 1:
          this.setDefault(row.id, row.thirdUser.thirdUserName);
          break;
        // 重新授权
        case 2:
          window.open("/api/thirdoauth/fbUpdate");
          break;
        // 修改状态
        case 3:
          this.showChangeStatus = true;
          this.changeStatusId = row.id;
          this.voucherStatus = row.status;
          break;
        // 重命名
        case 4:
          this.$prompt("请输入帐户新名字", "重命名", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              this.rename(row.id, value);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消重命名",
              });
            });
          break;
        // 绑定优化师
        case 6:
          this.$prompt("请输入单点账号id,多个请逗号隔开", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              if (value) {
                this.bindUid(row.id, value);
              } else {
                this.$message({
                  type: "warning",
                  message: "请输入单点账号id",
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消输入",
              });
            });
          break;
        // 同步账户
        case 7:
          this.pullMcc(row.id);
          break;
        case 8:
          this.unbind(row.id);
          break;
      }
    },
    getUserList() {
      const { page, pageSize } = this;
      const data = {
        page,
        pageSize,
      };
      findAll(data)
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.content;
            this.total = res.data.totalElements;
          }
        })
        .catch((err) => {
          this.$message.error(err.comment);
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getUserList();
    },
    // 点击切换账号
    async changeCurrentNum(id) {
      await this.$store.dispatch("num/setSelectedNum", {
        selectedNum: id,
      });
      localStorage.removeItem("activeAdAccount");
      sessionStorage.removeItem('activeAdAccount')
      this.$bus.$emit("trigger-getAdAccounts", {});
    },
    async getTableHeight() {
      await this.$nextTick()
      if (this.tableHeightData.length) {
        this.tableHeight = tableHeight(this.tableHeightData);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/commonly";
.auth-wrap {
  padding: 8px;
  .content {
    padding: 0 32px;
  }
  .cus-header {
    padding: 16px 0;
    margin-bottom: 8px;
  }
  .cus-table.el-table {
    color: rgba(0, 0, 0, 0.85);
    th {
      background: #fafafa !important;
    }
    thead {
      tr th {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .cell {
      padding: {
        left: 16px;
        right: 16px;
      }
    }
    th,
    td {
      padding: {
        top: 16px;
        bottom: 16px;
      }
    }
    .el-table__row:nth-child(2n-1) {
      background: #f8f8fa;
    }
    i {
      margin-right: 0;
    }
    .el-table__body-wrapper {
      height: auto !important;
    }
  }
}
.static-btn {
  color: #3b87e5;
  border: 1px solid #c8e0ff;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 12px;
  cursor: pointer;
  word-break: keep-all;
}
.small-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>

<template>
  <el-dialog title="同步凭证" :visible.sync="dialogVisible">
    <el-container>
      <el-header style="text-align: right; font-size: 12px">
        <div class="btn-wrap">
          <a type="primary" target="_blank" :href="'https://fbapi.giikin.com/adreport/credentail/addForm?credentailId=0&credentialSubType=common&platform=google'" >创建凭证</a>
          <el-tooltip :content="'添加后，需要再同步一下'">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      </el-header>
      <el-main>
        <el-checkbox-group v-model="checkboxGroup">
          <ul class="voucher-list">
            <li v-for="(item, i) in list" :key="i">
              <el-checkbox :label="item.id">
                <div class="voucher-item">
                  <div class="voucher-title">{{ item.name }}</div>
                  <div class="voucher-note">【ID={{item.id}}】{{ item.note }}</div>
                </div>
              </el-checkbox>
              <a target="_blank" :href="'https://fbapi.giikin.com/adreport/credentail/addForm?credentailId='+item.id+'&credentialSubType=common&platform=google'">更新凭证</a>
              <el-tooltip :content="'更新后，需要再同步一下'">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </li>
          </ul>
        </el-checkbox-group>
      </el-main>
    </el-container>
    <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkboxGroup: [],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  watch: {
    show(val) {
      this.checkboxGroup = [];
    },
  },
  methods: {
    confirm() {
      if (!this.checkboxGroup.length) {
        return this.$message.error("请选择凭证");
      }
      this.$emit("onsync", this.checkboxGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrap {
  padding-bottom: 10px;
  text-align: right;
}
.voucher-list {
  border-top: 1px solid #efefef;
  li {
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 0 16px;
    justify-content: space-between;
    &:hover {
      background: #fafafa;
    }
    ::v-deep .el-checkbox {
      display: flex;
      align-items: center;
    }
  }
  .voucher-title {
    font-size: 14px;
    color: #333;
  }
  .voucher-note {
    font-size: 12px;
    color: #888;
  }
}
</style>